<template>
  <div class="congratulations-wrapper">
    <img src="@/assets/img/icons/congratulations.svg" alt="">
    <div class="title mt-5">{{ $t('congratulations.congratulations') }}!</div>
    <div class="desc mt-2">{{ $t('congratulations.enter_your_email') }}</div>
    <div class="input-wrapper position-relative m-auto mt-5">
      <input
          v-model="email"
          type="text"
          class="text-input text-center w-100"
          :class="{error: validationError}"
          :placeholder="$t('congratulations.your_email')"
          @input="validationError = false"
      >
      <div v-if="validationError" class="error-message position-absolute w-100">
        {{ $t('congratulations.enter_valid_email') }}
      </div>
    </div>
    <div class="app-btn-wrapper m-auto">
      <b-button variant="success" class="app-btn w-100 mt-3" @click="getAccess">{{
          $t('congratulations.get_access')
        }}
      </b-button>
      <div class="desc mt-2">{{ $t('congratulations.we_respect_your_privacy') }}!</div>
    </div>
  </div>
</template>

<script>
import config from '@/config';
import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default {
  data: () => ({
    email: '',
    validationError: false
  }),
  beforeCreate() {
    // redirect to quiz if not completed
    const questions = localStorage.getItem('questions');
    const answers = localStorage.getItem('answers');
    const isQuizCompleted = questions && answers
        ? JSON.parse(questions).length === Object.keys(JSON.parse(answers)).length
        : false;

    if (!isQuizCompleted) {
      this.$router.push({name: 'quiz'}).catch(() => {
      });
    }
  },
  beforeMount() {
    this.initFBPixel(this.fbPixelId);
    this.initClarity(config.clarityId.onboardingId)
    Vue.use(VueGtag, {config: {id: config.gaId.onboarding.prod}});
  },
  mounted() {
    this.amplitudeEvent('Congratulations shown');
    this.gtagEvent('Page_View', {event_category: 'View', event_label: 'Page_View_Congratulations'});
    this.pixelEvent('PageView');
  },
  computed: {
    isAuthKeyInCookies() {
      return this.$cookies.isKey('auth_key');
    },
    isEmailExistsInCookies() {
      return this.$cookies.get('auth_email') === this.email;
    },
    fbPixelId() {
      return window.location.host === config.siteHost ? config.fbPixel.onboarding.prod : config.fbPixel.onboarding.dev;
    }
  },
  methods: {
    validateEmail() {
      this.validationError = false;
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      return pattern.test(this.email);
    },
    async getAccess() {
      if (!this.validateEmail()) {
        this.validationError = true;
        this.amplitudeEvent('Get access clicked', {email: 'invalid'});
        return false;
      }
      this.amplitudeEvent('Get access clicked', {email: 'valid'});

      if (!this.isAuthKeyInCookies || !this.isEmailExistsInCookies) {
        await this.$store.dispatch('appStore/preAuth', this.email).then(response => {
          this.$cookies.set('auth_key', response.data.auth_key, '1m', '/', config.siteHost, true, 'Lax');
          this.$cookies.set('auth_uuid', response.data.uuid, '1m', '/', config.siteHost, true, 'Lax');
          this.$cookies.set('auth_email', this.email, '1m', '/', config.siteHost, true, 'Lax');
        });
      }

      this.gtagEvent('Button_click', {event_category: 'Click', event_label: 'Button_click_Get_Access'});
      location.href = `https://${config.shopHost}/payment/membership`;
    }
  }
};
</script>

<style lang="scss" scoped>
.congratulations-wrapper {
  min-height: calc(100vh - 200px);
  padding-top: 75px;

  .title {
    font-size: 30px;
    font-weight: 600;
  }

  .input-wrapper {
    max-width: 500px;

    input.text-input {
      height: 56px;
      border-radius: 38px;
      border: 2px solid transparent;

      &.error {
        color: #F67272;
        border: 2px solid #F67272;
      }

      &::placeholder {
        color: #cfcfcf;
      }

      &:focus-visible {
        outline: none;
      }
    }

    .error-message {
      display: contents;
      font-size: 14px;
      color: #F67272;
    }
  }

  .app-btn-wrapper {
    width: 300px;

    .app-btn {
      padding: 15px 25px;
      background: #54A96C;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      border: 0;
      border-radius: 30px;
    }

    .desc {
      font-size: 13px;
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .congratulations-wrapper {
    min-height: calc(100vh - 225px);
  }
  .congratulations-wrapper {
    min-height: calc(100vh - 200px);
    padding-top: 75px;

    .title {
      font-size: 30px;
      font-weight: 600;
    }

    .input-wrapper {
      max-width: 300px;
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 375px) {
  .congratulations-wrapper {
    .input-wrapper {
      width: 300px;
    }

    //.app-btn-wrapper {
    //  width: 100%;
    //  position: fixed;
    //  bottom: 0;
    //  padding: 15px 25px;
    //}
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 320px) {
  .congratulations-wrapper {
    padding-top: 20px;

    .input-wrapper {
      width: 300px;
    }

    .app-btn-wrapper {
      width: 100%;
      position: fixed;
      bottom: 0;
      padding: 15px 25px;
    }
  }
}
</style>